<template>
  <div class="card">
    <h1 class="is-size-1">Wochenplan</h1>

    <div class="columns is-multiline">
      <div class="column is-one-quarter">
        <Wochentag wochentag="Montag" gericht="Nudeln">
        </Wochentag>
      </div>
      <div class="column is-one-quarter">
        <Wochentag wochentag="Dienstag" gericht="Dampfnudeln"/>
      </div>
      <div class="column is-one-quarter">
        <Wochentag wochentag="Mittwoch" gericht="Pelemini">
        </Wochentag>
      </div>
      <div class="column is-one-quarter">
        <Wochentag wochentag="Donnerstag"
                   gericht="Hühnersuppe"/>
      </div>
      <div class="column is-one-quarter">
        <Wochentag wochentag="Freitag"
                   gericht="Bananenmilchshake">
        </Wochentag>
      </div>
      <div class="column is-one-quarter">
        <Wochentag wochentag="Samstag"
                   gericht="Glasnudeln mit Soße"/>
      </div>
      <div class="column is-one-quarter">
        <Wochentag wochentag="Sonntag"
                   gericht="Sonntagsbraten"/>
      </div>
    </div>
  </div>
</template>

<script>
import Wochentag from "@/components/Wochentag";

export default {
  name: 'HelloWorld',
  components: {
    Wochentag
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
