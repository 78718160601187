<template>
  <div id="app">
    <div class="container">
      <div class="is-primary">

        <router-view/>

      </div>
    </div>

  </div>
</template>

<script>
//import Wochentag from './components/Wochentag.vue'

export default {
  name: 'App',
  components: {
    //Wochentag
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
