import Vue from 'vue'
import Router from 'vue-router'
import Woche from "@/components/Woche";
//import store from './store.js'
//import Wochentag from "@/components/Wochentag";

Vue.use(Router)


let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Woche,
      meta: {
        title: 'Christliche Gemeinde Karlsruhe',
        requiresAuth: true
      }
    },
    {
      path: '/gericht/:id',
      name: 'gericht',
      meta: {
        title: 'Christliche Gemeinde Karlsruhe - Veranstaltung Detail',
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "veranstaltung" */ './components/Gericht.vue')
    },
    {
      path: '/gerichte',
      name: 'gerichte',
      meta: {
        title: 'Christliche Gemeinde Karlsruhe - Veranstalltung Einstellungen anlegen',
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "veranstaltungSettings" */ './components/Gerichte.vue')
    }
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

/*
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})
*/

export default router